<!-- *************************** 分销记录 ****************************** -->
<template>
  <div class="preview">
    <h1 class="title">分销记录</h1>
    <div>
      <el-table :data="tableData" stripe style="width: 100%" border v-loading="loading">
        <el-table-column prop="credit" label="推广奖励金额">
          <template slot-scope="scope">{{scope.row.credit/100}}</template>
        </el-table-column>
<!--        <el-table-column prop="customer_user_id" label="被推广用户id" width="180"></el-table-column>-->
        <el-table-column prop="created_at" label="奖励到账时间"></el-table-column>
        <el-table-column prop="customer_user_name" label="被推广用户名"></el-table-column>
        <el-table-column prop="describe" label="描述"></el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-sizes="[20, 40, 60, 200]"
        :page-size="size"
        layout="sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import {getSaleListApi} from "api/AdminCenter";

export default {
  data() {
    return {
      loading: true,
      current_page: 1,
      total: 1,
      size: 15,
      tableData: [],
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单关闭"
      }
    };
  },
  methods: {
    //每页条数
    handleSizeChange(val) {
      this.getList({ size: val, page: this.current_page });
    },
    //当前第几页
    handleCurrentChange(val) {
      this.getList({ page: val, size: this.size });
    },
    getList(values) {
      getSaleListApi(values).then(res => {
        if (res.code == 0) {
          this.loading = false;
          const { current_page, total, data } = res.data;
          this.tableData = data;
          this.current_page = current_page;
          this.total = total;
        }
      });
    }
  },
  mounted() {
    this.getList({ size: this.size });
  }
};
</script>
<style lang="scss" scoped>
.preview {
  background: #fff;
  padding: 50px;
  .title {
    margin-bottom: 50px;
    font-size: 20px;
    color: #1a1a1a;
    text-align: left;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: center;
  }
  ::v-deep .el-table th {
    color: #fff;
    background: #4353b6;
  }
  ::v-deep .el-table__body {
    width: 100% !important;
  }
  ::v-deep .el-table__header {
    width: 100% !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
}
</style>